import toast from 'react-hot-toast';
import { REGEX } from './constant';

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required'
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    }
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  })
};

export const getUserInitials = (username) => {
  return username
    ? username
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase()
    : 'NA';
};

export const notify = (type, message) => {
  switch (type) {
    case 'success':
      toast.success(message, {
        duration: 4000,
        position: 'top-right'
      });
      break;

    case 'error':
      toast.error(message, {
        duration: 4000,
        position: 'top-right'
      });
      break;
    default:
      break;
  }
};
