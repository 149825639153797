import { AppContext } from 'AppContext';
import { LOGOUT, SET_CURRENT_EMAIL } from 'common/actionTypes';
import { ROUTES } from 'common/constant';
import UserDropdown from 'components/Dropdowns/UserDropdown';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const router = useRouter();
  const {
    state: { currentUser },
    dispatch
  } = useContext(AppContext);

  const handleLogout = () => {
    dispatch({
      type: SET_CURRENT_EMAIL,
      data: null
    });
    dispatch({ type: LOGOUT });
    router.push(ROUTES.LOGIN);
  };

  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            {!Object.keys(currentUser)?.length ? (
              <Link href={ROUTES.LANDING}>
                <a
                  className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                  href="#"
                >
                  Card Scanner
                </a>
              </Link>
            ) : (
              <span className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase">
                Card Scanner
              </span>
            )}
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-white fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              'lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none' +
              (navbarOpen ? ' block rounded shadow-lg' : ' hidden')
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center">
                <a
                  className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="#"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{' '}
                  Docs
                </a>
              </li>
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <UserDropdown />
              </li>
              <li className="flex items-center">
                {!Object.keys(currentUser)?.length &&
                !router?.pathname.includes('login') ? (
                  // eslint-disable-next-line @next/next/link-passhref
                  <Link href={ROUTES.LOGIN}>
                    <button
                      className="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Login
                    </button>
                  </Link>
                ) : (
                  Object?.keys(currentUser)?.length > 0 && (
                    <button
                      className="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  )
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
