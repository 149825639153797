import { useLazyQuery, useMutation } from '@apollo/client';
import { AppContext } from 'AppContext';
import { ROUTES, SCAN_LIMIT, SUBSCRIPTION_ID } from 'common/constant';
import { notify } from 'common/utils';
import Footer from 'components/Footers/Footer.js';
import Navbar from 'components/Navbars/AuthNavbar.js';
import PlanModal from 'components/PlanModal';
import { CREATE_CHECKOUT } from 'graphql/mutations/createCheckoutSession';
import { USER_PACKAGE } from 'graphql/mutations/userPackage';
import { GET_PRICING_FEATURE } from 'graphql/queries/getPricingFeature';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';

export default function Landing() {
  const [quantity, setQuantity] = useState(1);
  const [totalScans, setTotalScans] = useState(0);
  const [modalOpen, isModalOpen] = useState(false);
  const [pricingData, setPricingData] = useState(0);
  const [userPlanDetail, setUserPlanDetail] = useState();
  const [loading, setLoading] = useState(true);
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const router = useRouter();

  const [userPackage] = useMutation(USER_PACKAGE, {
    onCompleted({ userPackage }) {
      setUserPlanDetail(userPackage?.userPackage);
    },
    onError() {}
  });

  const [checkout] = useMutation(CREATE_CHECKOUT, {
    onCompleted(data) {
      window.location.href = data?.createCheckoutSession?.url;
    },
    onError() {}
  });

  const [getPricingFeature] = useLazyQuery(GET_PRICING_FEATURE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const { getPricingFeature } = res;
      setTotalScans(quantity * SCAN_LIMIT * getPricingFeature?.[0]?.price);
      setPricingData(getPricingFeature?.[0]);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  const handleScanQuantity = (e) => {
    setQuantity(e?.target?.value);
  };

  useEffect(() => {
    getPricingFeature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quantity > 0) {
      setTotalScans(quantity * SCAN_LIMIT * pricingData?.price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const handlePlanBuy = () => {
    if (Object.keys(currentUser)?.length) {
      userPackage({
        variables: {
          input: {
            noOfScans: parseInt(quantity * SCAN_LIMIT, 10)
          }
        }
      })
        .then((data) => {
          if (!data?.data?.userPackage?.userPackage) {
            notify('error', data?.data?.userPackage?.message);
          } else {
            isModalOpen(true);
          }
        })
        .catch(() => {
          notify('error', 'Something went wrong');
        });
    } else {
      router.push(ROUTES.LOGIN);
    }
  };

  const handleCloseModal = () => {
    isModalOpen(false);
  };

  const handlePay = () => {
    if (currentUser?.email) {
      checkout({
        variables: {
          input: {
            email: currentUser?.email,
            packageId: userPlanDetail?.id,
            subscriptionId: SUBSCRIPTION_ID,
            quantity: parseInt(quantity, 10)
          }
        }
      });
    }
  };

  useEffect(() => {
    if (Object?.keys(currentUser)?.length > 0) {
      router.push(ROUTES.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (loading) {
    return (
      <div>
        <div className="top-0 left-0 w-full h-full block z-50 absolute bg-black bg-opacity-50"></div>
        <div className="my-32 mx-auto max-w-sm text-center relative z-50 top-0">
          <div className="block mb-4">
            <i className="fas fa-circle-notch animate-spin text-white mx-auto text-6xl"></i>
          </div>
          <h4 className="text-lg font-medium text-white">
            Loading page contents
          </h4>
        </div>
      </div>
    );
  }

  return (
    <>
      <Navbar transparent />
      <Toaster />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')"
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Card Scanner.
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                    A simple website for card scanning using API key.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16"
            style={{ transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Card details</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Get all the card details in seconds by just scanning the
                      card.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Free scans</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Free card scans for the users to get the experience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Secure data</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Don't worry, your details are secured with us and we are
                      just a click away!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full ml-auto mr-auto px-4">
                <div className="md:pr-12 flex justify-items-center items-center flex-col">
                  <h3 className="text-4xl font-semibold mb-8">Pricing</h3>
                  <div className="flex items-center mb-4">
                    <input
                      type="number"
                      className="width-clamp text-center border-0 text-4xl px-3 py-3 font-semibold text-xl placeholder-blueGray-700 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-16 ease-linear transition-all duration-150"
                      defaultValue="1"
                      min="1"
                      onChange={handleScanQuantity}
                    />
                    <span className="ml-2 font-semibold text-4xl text-blueGray-700">
                      x <span>{SCAN_LIMIT}</span> =
                    </span>
                    <span className="ml-2 font-semibold text-4xl text-blueGray-700">
                      <span>{SCAN_LIMIT * quantity}</span> <span>x $</span>
                      <span>{pricingData?.price}</span> =
                    </span>
                    <span className="ml-2 font-semibold text-4xl text-blueGray-700">
                      <span>$</span>
                      <span>{totalScans}</span>
                    </span>
                    <span>
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ml-3 mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handlePlanBuy}
                      >
                        Buy now
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <PlanModal
        isOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        userPlanDetail={userPlanDetail}
        handlePay={handlePay}
      />
    </>
  );
}
