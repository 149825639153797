import { gql } from '@apollo/client';

export const USER_PACKAGE = gql`
  mutation userPackage($input: UserPackageInput!) {
    userPackage(input: $input) {
      userPackage {
        id
        noOfScans
        price
        isActive
        status
        paymentStatus
        subscriptionId
        remainingScans
      }
      message
    }
  }
`;
