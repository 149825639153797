import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '500px',
    height: '200px'
  }
};

export default function PlanModal({
  isOpen,
  handleCloseModal,
  handlePay,
  userPlanDetail
}) {
  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {/* <h2>New Plan</h2> */}
      <button onClick={handleCloseModal} className="float-right">
        X
      </button>
      <div>
        For {userPlanDetail?.noOfScans} scans, total amount to be paid is $
        {userPlanDetail?.price}
      </div>
      <div>
        <button
          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-20 ease-linear transition-all duration-150"
          type="button"
          onClick={handlePay}
        >
          Pay
        </button>
      </div>
    </Modal>
  );
}
