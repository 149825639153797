import { AppContext } from 'AppContext';
import { getUserInitials } from 'common/utils';
import React, { useContext } from 'react';

const UserDropdown = () => {
  const {
    state: { currentUser }
  } = useContext(AppContext);

  return (
    <>
      {currentUser?.firstName && (
        <div className="items-center flex">
          <span
            className="w-10 h-10 text-sm text-white bg-red-500 inline-flex items-center justify-center rounded-full"
            title={currentUser?.firstName}
          >
            {getUserInitials(currentUser?.firstName)}
          </span>
        </div>
      )}
    </>
  );
};

export default UserDropdown;
