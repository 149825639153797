import { gql } from '@apollo/client';

export const CREATE_CHECKOUT = gql`
  mutation createCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(input: $input) {
      id
      url
    }
  }
`;
